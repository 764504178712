import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react";
import { toPairs } from "lodash";

const _ErrorModal = props => {
  const { store } = props;

  const handleModalClose = e => {
    store.errModal = false;
    store.formErr = undefined;
  };

  const showErrorList = store => {
    const errList = toPairs(store.formErr);
    return errList.map((o, i) => {
      return <div key={i}>{o[1]}</div>;
    });
  };

  return (
    <Modal open={store.errModal} basic onClose={handleModalClose}>
      <Modal.Content>
        <h3> We have some errors</h3>
        {showErrorList(store)}
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={handleModalClose} inverted>
          <Icon name="checkmark" /> Got it
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const ErrorModal = observer(_ErrorModal);

export default ErrorModal;
