import React, { Component } from "react";
import { Provider, observer } from "mobx-react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import "./App.css";

import StageOnePage from "./pages/StageOnePage";
import StageTwoPage from "./pages/StageTwoPage";
import SignUpStore from "./stores/SignUpStore";
import { routes } from "./lib/routes";
import SessionProxy from "./pages/SessionProxy";

const NoMatch = props => {
  console.log("No match");
  return <Redirect to={"/1"} />;
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const store = new SignUpStore();
    return (
      <Provider store={store}>
        <div className="App">
          <div className={"App-header"}>
            <div className={"App-logo"}>
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="140px"
                height="80px"
                viewBox="0 0 1200 200"
              >
                <path
                  fill="red"
                  d="M280.752,90.249l-28.881,98.646h60.388l-16.128,55.512h-60.763L203.111,354.68h67.139l-31.882,58.138
	H122.844l94.895-322.568H280.752z M343.391,81.247H190.358l34.507-54.386h134.279L343.391,81.247z"
                />
                <path
                  fill="red"
                  d="M345.637,140.135H452.91l-14.628,49.135h-50.261l-17.628,60.013h49.135l-14.253,48.385h-49.136
	l-18.754,63.389h52.136l-18.754,51.761H265.37L345.637,140.135z"
                />
                <path
                  fill="red"
                  d="M492.293,261.285l-65.264,219.797h-56.637l100.521-340.947h56.262l9.752,95.27l63.763-95.27h58.512
	l-80.642,272.683h-56.637l44.634-151.532l-62.263,85.144L492.293,261.285z"
                />
                <path
                  fill="red"
                  d="M677.58,140.135h107.273l-14.628,49.135h-50.261l-17.629,60.013h49.135l-14.253,48.385h-49.135
	l-18.754,63.389h52.136l-18.754,51.761H597.313L677.58,140.135z"
                />
                <path
                  fill="red"
                  d="M758.973,481.082h-56.637l100.521-340.947h103.147c12.248,0,20.5,3.504,24.755,10.502
	c4.249,7.003,4.999,15.003,2.251,24.005l-27.381,92.644c-1.255,4.003-3.815,7.689-7.689,11.065
	c-3.88,3.376-9.318,5.063-16.315,5.063c4.248,0.75,7.876,3.505,10.877,8.252c3.001,4.753,3.001,12.002,0,21.754l-29.256,99.396
	h-56.638l31.507-106.897c0.75-2.251,0.809-4.372,0.188-6.377c-0.627-1.998-2.689-3-6.188-3h-18.754L758.973,481.082z
	 M844.49,256.409c2.749,0,5.311-0.686,7.689-2.063c2.374-1.371,4.185-4.184,5.438-8.438l15.004-50.636
	c2.25-7.502-0.628-11.252-8.627-11.252h-17.629l-21.38,72.39H844.49z"
                />
                <path
                  fill="red"
                  d="M1084.912,140.135l-14.628,49.135h-58.513c-3,0-5.315,0.504-6.938,1.5c-1.63,1.002-2.942,3.252-3.938,6.751
	L954.01,356.556c-2.005,7.255,0.873,10.877,8.627,10.877h19.129l20.629-69.765h-11.627l14.253-48.385h65.264l-45.01,153.033
	l-63.014,31.882l6.002-21.38H933.38c-6.505,0-12.507-0.938-18.004-2.813c-5.503-1.875-10.068-4.876-13.69-9.002
	c-3.627-4.125-6.001-9.377-7.126-15.753s-0.316-14.065,2.438-23.067L954.76,165.64c3-10.25,7.56-17.066,13.69-20.442
	c6.124-3.376,13.69-5.063,22.692-5.063H1084.912z"
                />
              </svg>
            </div>
          </div>
          <div className={"App-body"}>
            <Router>
              <Switch>
                <Route path={routes.start.path} component={StageOnePage} />
                <Route path={routes.updl.path} component={StageTwoPage} />
                <Route
                  path={routes.retses.path}
                  render={props => <SessionProxy {...props} store={store} />}
                />
                <Route component={NoMatch} />
              </Switch>
            </Router>
          </div>
          <div className={"copyright"}>&copy; 2018 EEMERG</div>
          <div className={"buildnum"}>
            BUILD #:
            {process.env.REACT_APP_BUILD_DATE}.{process.env.REACT_APP_VERSION}
          </div>
        </div>
      </Provider>
    );
  }
}

export default App;
