import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { Form, Icon, Divider, Button, Step, Input } from "semantic-ui-react";
import Confirmation from "../components/ConfirmationComponent";

import { routes } from "../lib/routes";
import {
  US_STATES,
  VEHICLE_TYPE,
  SERVICE_AREA
} from "eemerg-models/dist/app_constants";

class _SignUpPage extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    if (location.pathname === routes.confirm.path) {
      this.state = { confirm: true };
    } else {
      this.state = { confirm: false };
    }
  }
  componentDidMount() {
    this.setState({ password: "password", icon: "eye" });
    this.props.store.signupLoc = "/";
  }

  handleInput = (e, data) => {
    const { name, value } = data;
    const { store } = this.props;

    switch (name) {
      case "email":
        store[name] = value.toLowerCase();
        break;
      case "password":
      case "vehicleType":
      case "maxServiceArea":
        store[name] = value;
        break;
      default:
        store[name] = value.toUpperCase();
    }
  };

  handleSee = () => {
    if (this.state.password == "password") {
      this.setState({ password: "text", icon: "eye slash" });
    } else {
      this.setState({ password: "password", icon: "eye" });
    }
  };
  checkEmailAvail = () => {
    const { store } = this.props;
    store.checkIfEmailExist();
  };

  render() {
    const { store } = this.props;

    if (store.signupStatus >= store.SUCCESSFUL) {
      return <Redirect to={routes.complete.path} />;
    }

    //Check to see if the confirm url is being requested without a valid signup form
    if (
      this.props.location.pathname === routes.confirm.path &&
      !this.props.store.validSignupForm
    ) {
      return <Redirect to={routes.start.path} />;
    }

    return (
      <div className={"SignUpPage"}>
        <br />

        {!this.state.confirm ? (
          <div className={"SignUpPage-body"}>
            <Form loading={store.working} size={"mini"}>
              <Divider horizontal>Account Holder Information</Divider>
              <Form.Group widths={"equal"}>
                <Form.Input
                  label={"First Name"}
                  value={store.firstName}
                  name={"firstName"}
                  onChange={this.handleInput}
                  data-testid={"firstName"}
                />
                <Form.Input
                  label={"Last Name"}
                  value={store.lastName}
                  name={"lastName"}
                  onChange={this.handleInput}
                  data-testid={"lastName"}
                />
              </Form.Group>

              <Form.Group widths={"equal"}>
                <Form.Field>
                  <Form.Input
                    value={store.email}
                    fluid
                    label={"Email"}
                    name={"email"}
                    onChange={this.handleInput}
                    onBlur={this.checkEmailAvail}
                    data-testid={"email"}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    value={store.phone}
                    fluid
                    label={"Phone"}
                    name={"phone"}
                    onChange={this.handleInput}
                    data-testid={"phone"}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <label>Password</label>
                <Input
                  icon
                  value={store.password}
                  fluid
                  name={"password"}
                  type={this.state.password}
                  onChange={this.handleInput}
                  data-testid={"password"}
                >
                  <input />
                  <Icon name={this.state.icon} link onClick={this.handleSee} />
                </Input>
              </Form.Field>

              <Divider horizontal>Company Information</Divider>
              <Form.Input
                label={"Company Name"}
                value={store.companyName}
                name={"companyName"}
                onChange={this.handleInput}
                data-testid={"companyName"}
              />
              <Form.Group widths={"equal"}>
                <Form.Input
                  label={"Address"}
                  value={store.address}
                  name={"address"}
                  onChange={this.handleInput}
                  data-testid={"address"}
                />
                <Form.Input
                  label={"City"}
                  value={store.city}
                  name={"city"}
                  onChange={this.handleInput}
                  data-testid={"city"}
                />
                <Form.Select
                  label={"State"}
                  value={store.state}
                  options={US_STATES}
                  name={"state"}
                  onChange={this.handleInput}
                  data-testid={"state"}
                />
                <Form.Input
                  label={"Zip"}
                  value={store.zip}
                  name={"zip"}
                  onChange={this.handleInput}
                  data-testid={"zip"}
                />
              </Form.Group>

              <Divider horizontal>Service Information</Divider>

              <Form.Group widths={"equal"}>
                <Form.Select
                  label={"Vehicle Type"}
                  name={"vehicleType"}
                  value={store.vehicleType}
                  options={VEHICLE_TYPE}
                  onChange={this.handleInput}
                  data-testid={"vehicleType"}
                />
                <Form.Select
                  label={"Max Service Area"}
                  name={"maxServiceArea"}
                  value={store.maxServiceArea}
                  options={SERVICE_AREA}
                  onChange={this.handleInput}
                  data-testid={"maxServiceArea"}
                />
              </Form.Group>
            </Form>
          </div>
        ) : (
          <Confirmation store={store} />
        )}
      </div>
    );
  }
}

const SignUpPage = inject("store")(observer(_SignUpPage));

export default SignUpPage;
