import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import StepProgress from "../components/StepProgressComponent";
import { Dimmer, Divider, Loader } from "semantic-ui-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { routes } from "../lib/routes";
import SignUpPage from "./SignUpPage";
import StepNav from "../components/StepNavComponent";
import CompletePage from "./CompletePage";
import ErrorModal from "../components/ErrorModalComponent";

class StageOnePage extends Component {
  render() {
    const { store } = this.props;
    return (
      <>
        <div>
          <ErrorModal store={store} />
          <div>
            <StepProgress store={store} />
          </div>

          <Dimmer active={store.working}>
            <Loader active={store.working} />
          </Dimmer>
          <Route path={routes.start.path} exact component={SignUpPage} />
          <Route path={routes.confirm.path} exact component={SignUpPage} />
          <Route path={routes.complete.path} component={CompletePage} />
          <br />
          <Divider />
          <div>
            <StepNav store={store} />
          </div>
        </div>
      </>
    );
  }
}

export default inject("store")(observer(StageOnePage));
