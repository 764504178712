import React, { Component } from "react";
import { observer } from "mobx-react";

import { Button } from "semantic-ui-react";
import { withRouter, Redirect } from "react-router-dom";
import { routes } from "../lib/routes";

class StepNav extends Component {
  handleNext = () => {
    const { store } = this.props;

    switch (this.props.location.pathname) {
      case routes.start.path:
        this.handleStartAction();
        break;
      case routes.confirm.path:
        this.handleConfirmAction();
        break;
      case routes.updl.path:
        this.handleUploadDl();
        break;
      case routes.upins.path:
        this.handleUploadIns();
        break;
      case routes.taxinfo.path:
        this.handleTaxInfo();
        break;
      case routes.taxconfirm.path:
        this.handleTaxConfirm();
      default:
        break;
    }
    console.log("clicked");
  };

  handlePrev = () => {
    this.props.history.goBack();
  };

  handleStartAction = () => {
    const { store, history } = this.props;

    if (!store.performFormValidation()) {
      history.push(routes.confirm.path);
    }
  };

  handleConfirmAction = () => {
    //TODO Thinking about showing a modal letting them know provider is about to be created.
    const { store } = this.props;

    if (!store.performTermsValidation()) {
      store
        .createServiceProvider()
        .then(success => {
          this.props.history.push(routes.complete.path);
        })
        .catch(failed => {
          console.log("create failed");
        });
    }
  };

  handleUploadIns = () => {
    const { history, store } = this.props;
    console.log("check ins upload flag see if upload complete");
    if (!store.insUpload) {
      store.formErr = {
        driverLicense: "A copy of your insurance must be uploaded."
      };
      store.errModal = true;
    } else {
      history.push(routes.taxinfo.path);
    }
  };

  handleUploadDl = () => {
    const { history, store } = this.props;
    console.log("check dl upload flag see if upload complete");

    if (!store.licUpload) {
      store.formErr = {
        driverLicense: "Driver's license picture must be uploaded."
      };
      store.errModal = true;
    } else {
      history.push(routes.upins.path);
    }
  };

  handleTaxInfo = () => {
    const { history, store } = this.props;
    if (!store.performFinancialFormValidation()) {
      history.push(routes.taxconfirm.path);
    }
  };

  handleTaxConfirm = () => {
    const { history, store } = this.props;
    store.createSecureServiceProvider().then(success => {
      history.push(routes.finished.path);
    });
  };

  renderNextButton = () => {
    const { location, store } = this.props;

    if (
      location.pathname === routes.complete.path ||
      location.pathname === routes.finished.path
    ) {
      return null;
    }
    return (
      <Button
        basic
        floated={"right"}
        color={"black"}
        size={"small"}
        disabled={store.checking}
        loading={store.checking}
        onClick={this.handleNext}
      >
        Next
      </Button>
    );
  };

  renderPrevButton = () => {
    switch (this.props.location.pathname) {
      case routes.start.path:
      case routes.updl.path:
      case routes.finished.path:
        return null;
      case routes.confirm.path:
      case routes.upins.path:
      case routes.taxinfo.path:
      case routes.taxconfirm.path:
        return (
          <Button
            basic
            floated={"left"}
            color={"black"}
            size={"small"}
            onClick={this.handlePrev}
          >
            Prev
          </Button>
        );
    }
  };

  render() {
    return (
      <div className={""} style={{ width: "100%" }}>
        {this.renderPrevButton()}
        {this.renderNextButton()}
      </div>
    );
  }
}

export default withRouter(observer(StepNav));
