import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Divider, Form } from "semantic-ui-react";
import { COMPANY_TYPES, TAX_ID_TYPES } from "eemerg-models";
import { routes } from "../lib/routes";
import FinancialConfirmation from "../components/FinancialConfirmationComponent";

class FinancialsPage extends Component {
  constructor(props) {
    super(props);

    const { location } = props;

    console.log(props);

    if (location.pathname === routes.taxconfirm.path) {
      this.state = { confirm: true };
    } else {
      this.state = { confirm: false };
    }
  }

  handleInput = (e, data) => {
    const { name, value } = data;
    const { store } = this.props;
    switch (name) {
      case "w9companyType":
      case "w9taxIdType":
        store[name] = value;
        break;
      default:
        store[name] = value.toUpperCase();
    }
  };

  render() {
    const { store } = this.props;
    return (
      <>
        {!this.state.confirm ? (
          <div>
            <Divider horizontal>Financial Information</Divider>
            <sub>
              We require this information so we can issue payments to our
              service providers.
            </sub>
            <br />
            <br />

            <Form>
              <Form.Group widths={"equal"}>
                <Form.Input
                  label={"Bank Name"}
                  value={store.bankName}
                  name={"bankName"}
                  onChange={this.handleInput}
                  data-testid={"bankName"}
                />
                <Form.Input
                  label={"Routing Number"}
                  value={store.routingNumber}
                  name={"routingNumber"}
                  onChange={this.handleInput}
                  data-testid={"routingNumber"}
                />
                <Form.Input
                  label={"Account Number"}
                  value={store.accountNumber}
                  name={"accountNumber"}
                  onChange={this.handleInput}
                  data-testid={"accountNumber"}
                />
              </Form.Group>
            </Form>

            <Divider horizontal>Tax Information</Divider>
            <Form>
              <Form.Group widths={"equal"}>
                <Form.Select
                  label={"Company Type"}
                  value={store.w9companyType}
                  name={"w9companyType"}
                  onChange={this.handleInput}
                  options={COMPANY_TYPES}
                  data-testid={"w9companyType"}
                />
                <Form.Select
                  label={"Tax ID Type"}
                  value={store.w9taxIdType}
                  name={"w9taxIdType"}
                  onChange={this.handleInput}
                  options={TAX_ID_TYPES}
                  data-testid={"w9taxIdType"}
                />
                <Form.Input
                  label={"EIN/SSN"}
                  value={store.w9ssnEin}
                  name={"w9ssnEin"}
                  onChange={this.handleInput}
                  data-testid={"w9ssnEin"}
                />
              </Form.Group>
            </Form>
          </div>
        ) : (
          <FinancialConfirmation store={store} />
        )}
      </>
    );
  }
}

export default inject("store")(observer(FinancialsPage));
