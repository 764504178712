import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Dimmer, Divider, Loader } from "semantic-ui-react";
import { routes } from "../lib/routes";
import StepNav from "../components/StepNavComponent";
import StageTwoStepProgress from "../components/StageTwoStepProgressComponent";

import DriverLicenseUploadPage from "./DriverLicenseUploadPage";
import InsuranceUploadPage from "./InsuranceUploadPage";
import ErrorModal from "../components/ErrorModalComponent";
import FinancialsPage from "./FinancialsPage";
import FinishedPage from "./FinishedPage";

class StageTwoPage extends Component {
  render() {
    const { store } = this.props;
    if (store.signupStatus < store.SUCCESSFUL) {
      return <Redirect to={routes.start.path} />;
    }
    return (
      <>
        <div>
          <ErrorModal store={store} />
          <div>
            <StageTwoStepProgress store={store} />
          </div>

          <Dimmer active={store.working}>
            <Loader active={store.working} />
          </Dimmer>
          <Route
            exact
            path={routes.updl.path}
            component={DriverLicenseUploadPage}
          />
          <Route path={routes.upins.path} component={InsuranceUploadPage} />
          <Route path={routes.taxinfo.path} component={FinancialsPage} />
          <Route path={routes.taxconfirm.path} component={FinancialsPage} />
          <Route path={routes.finished.path} component={FinishedPage} />
          <br />
          <Divider />
          <div>
            <StepNav store={store} />
          </div>
        </div>
      </>
    );
  }
}

export default inject("store")(observer(StageTwoPage));
