import firebase from "firebase";
import { computed, decorate, observable, action } from "mobx";
import config from "../config";
import { ServiceProvider } from "eemerg-models";
import {
  SignupValidator,
  EmailRule,
  FinancialValidator,
  TermsValidator
} from "../lib/form_rules";

class SignUpStore {
  INITIAL = 1;
  SUCCESSFUL = 2;
  COMPLETE = 3;
  ERRORS = 0;

  constructor() {
    this.fbClient = firebase.initializeApp(config);

    this.local_u = "";
    this.local_p = "";
    this.termsConditions = false;
    this.checking = false;
    this.uid = undefined;
    this.completeLater = false;
    this.fileUploading = false;
    this.licUpload = false;
    this.insUpload = false;
    this.working = false;
    this.signupStatus = 1;
    this.emailAddressAvailable = false;
    this.errModal = false;
    this.formErr = undefined;
    this.signupLoc = "/";
    this.email = "";
    this.password = "";
    this.accountType = "DISPATCHER";
    this.phone = "";
    this.firstName = "";
    this.lastName = "";
    this.companyName = "";
    this.address = "";
    this.city = "";
    this.state = "";
    this.zip = "";
    this.bankName = "";
    this.accountNumber = undefined;
    this.routingNumber = undefined;
    this.vehicleType = "SV";
    this.maxServiceArea = "";
    this.coverage = [];

    this.w9companyType = "";
    this.w9taxIdType = "";
    this.w9ssnEin = "";
  }

  resetSignup() {
    this.local_u = "";
    this.local_p = "";
    this.uid = undefined;
    this.termsConditions = false;
    this.checking = false;
    this.insUpload = false;
    this.completeLater = false;
    this.fileUploading = false;
    this.licUpload = false;
    this.insUpload = false;
    this.working = false;
    this.signupStatus = 1;
    this.emailAddressAvailable = false;
    this.errModal = false;
    this.formErr = undefined;
    this.signupLoc = "/";
    this.email = "";
    this.password = "";
    this.accountType = "DISPATCHER";
    this.phone = "";
    this.firstName = "";
    this.lastName = "";
    this.companyName = "";
    this.address = "";
    this.city = "";
    this.state = "";
    this.zip = "";
    this.bankName = "";
    this.accountNumber = undefined;
    this.routingNumber = undefined;
    this.vehicleType = "SV";
    this.maxServiceArea = "";
    this.coverage = [];
    this.w9companyType = "";
    this.w9taxIdType = "";
    this.w9ssnEin = "";
  }

  async createServiceProvider() {
    this.working = true;
    let _zips;
    let _;
    try {
      try {
        _zips = await this.getZipCodes();
      } catch (err) {
        throw new Error("Problem with zipcode");
      }

      //Keep the raw zip data
      this.coverage = _zips.data;

      //Convert an array of just zip codes
      const z = JSON.parse(_zips.data).zip_codes.map(o => {
        return o.zip_code;
      });

      let newSp = await this.fbClient
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password);

      this.uid = newSp.user.uid;

      const scsp = this.fbClient.functions().httpsCallable("set_claim_sp");

      _ = await scsp({ uid: this.uid });

      //reset user info with claim
      this.fbClient.auth().signOut();
      _ = await this.login();

      const _sp = {
        active: false,
        notifyOn: ["ANY"], // Notify by service type
        notifyBy: "BOTH", //Notify by VOICE, SMS, or BOTH
        id: this.uid,
        email: this.email.trim(),
        phone: this.phone,
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        accountType: this.accountType,
        companyName: this.companyName.trim(),
        address: this.address.trim(),
        city: this.city.trim(),
        state: this.state,
        zip: this.zip.trim(),
        vehicleType: this.vehicleType,
        maxServiceArea: this.maxServiceArea,
        coverage: this.coverage,
        zipArr: z,
        serviceCallCount: 0,
        serviceRatingCount: 0,
        serviceRatingPoints: 0,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };

      console.log("sp account created!");

      const spDocData = await this.fbClient
        .firestore()
        .collection("serviceProvider")
        .doc(newSp.user.uid)
        .set(_sp);

      console.log("sp data saved");

      console.log("ready to save session");
      _ = await this.saveSession();

      this.signupStatus = this.SUCCESSFUL;
      this.working = false;
      return spDocData;
    } catch (err) {
      console.log(err);
      console.log({
        ername: err.name,
        errmsg: err.message,
        errcode: err.code,
        errraw: err
      });
      this.working = false;
      throw err;
    }
  }

  async createSecureServiceProvider() {
    this.working = true;
    const user = await this.login();
    try {
      const spDocBankData = await this.fbClient
        .firestore()
        .collection("serviceProviderSecure")
        .doc(this.uid)
        .set({
          id: this.uid,
          bankName: this.bankName.trim(),
          routingNumber: this.routingNumber,
          accountNumber: this.accountNumber,
          w9taxIdType: this.w9taxIdType,
          w9companyType: this.w9companyType,
          w9ssnEin: this.w9ssnEin,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });

      const spDocSignup = await this.fbClient
        .firestore()
        .collection("signupSession")
        .doc(this.uid)
        .update({ s: "c" });
      this.fbClient.auth().signOut();
      this.signupStatus = this.COMPLETE;
      this.working = false;
      return true;
    } catch (err) {
      console.log(err);
      this.working = false;
      return false;
    }
  }

  get validSignupForm() {
    return SignupValidator.test(this.validationSignupMap());
  }

  get validFinancialForm() {
    return FinancialValidator.test(this.validationFinancialMap());
  }

  validationSignupMap() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      companyName: this.companyName,
      emailAddressAvailable: this.emailAddressAvailable,
      email: this.email,
      phone: this.phone,
      password: this.password,
      address: this.address,
      city: this.city,
      state: this.state,
      zip: this.zip,
      bankName: this.bankName,
      routingNumber: this.routingNumber,
      accountNumber: this.accountNumber,
      vehicleType: this.vehicleType,
      maxServiceArea: this.maxServiceArea
    };
  }

  validationFinancialMap() {
    return {
      bankName: this.bankName,
      routingNumber: this.routingNumber,
      accountNumber: this.accountNumber,
      w9companyType: this.w9companyType,
      w9taxIdType: this.w9taxIdType,
      w9ssnEin: this.w9ssnEin
    };
  }

  performFormValidation() {
    const val = !this.validSignupForm;
    if (val) {
      this.errModal = true;
      this.formErr = SignupValidator.getErrors(this.validationSignupMap());
    }

    return val;
  }

  performFinancialFormValidation() {
    const val = !this.validFinancialForm;
    if (val) {
      this.errModal = true;
      this.formErr = FinancialValidator.getErrors(
        this.validationFinancialMap()
      );
    }
    return val;
  }

  performTermsValidation() {
    const val = !TermsValidator.test({ termsConditions: this.termsConditions });
    if (val) {
      this.errModal = true;
      this.formErr = TermsValidator.getErrors({
        termsConditions: this.termsConditions
      });
    }
    return val;
  }

  checkIfEmailExist() {
    this.checking = true;
    const cfspe = this.fbClient
      .functions()
      .httpsCallable("check_for_service_provider_email");

    if (EmailRule.test(this.email)) {
      console.log("checking email");
      cfspe({ email: this.email })
        .then(success => {
          console.log(`switching to ${!success.data}`);
          this.checking = false;
          this.emailAddressAvailable = !success.data;
        })
        .catch(err => {
          console.log("error emai");
          this.checking = false;
        });
    } else {
      this.emailAddressAvailable = false;
      this.checking = false;
    }
  }

  getZipCodes() {
    const zipCodeInRadius = this.fbClient
      .functions()
      .httpsCallable("zips_in_radius");
    return zipCodeInRadius({
      zip: this.zip,
      m: this.maxServiceArea,
      u: "mile"
    });
  }

  async login() {
    const user = await this.fbClient
      .auth()
      .signInWithEmailAndPassword(this.email, this.password);
    return user;
  }

  setQuickLogin() {
    this.local_u = this.email;
    this.local_p = this.password;
  }

  async uploadLicense(file, name) {
    this.fileUploading = true;
    const user = await this.login();

    const storageRef = this.fbClient.storage().ref();

    var imageRef = storageRef.child(
      `lic/${user.user.uid}/license_${Date.now().toString()}.jpg`
    );

    console.log(imageRef);

    imageRef.put(file).then(snapshot => {
      console.log(snapshot);
      this.fileUploading = false;
      this.licUpload = true;
    });
    this.fbClient.auth().signOut();
  }

  async uploadInsurance(file, name) {
    this.fileUploading = true;
    const user = await this.login();

    const storageRef = this.fbClient.storage().ref();

    let imageRef = storageRef.child(
      `ins/${user.user.uid}/insurance${Date.now().toString()}.jpg`
    );

    const token = await imageRef.put(file);
    this.fileUploading = false;
    this.insUpload = true;

    const metaData = await imageRef.getDownloadURL();

    this.fbClient.auth().signOut();
  }

  async sendSessionEmailLink() {
    //Grab sp send email with cloud function
    const spEmailSender = this.fbClient
      .functions()
      .httpsCallable("sp_email_sender");
    //Prepare link for email
    const link = `${process.env.REACT_APP_SIGNUP_CONTINUE_LINK}/${this.uid}`;
    //Execute send email link
    return spEmailSender({
      email: this.email,
      session_link: link,
      to: "init_signup"
    });
  }

  async sendSessionSmsLink() {
    const sms_sender = this.fbClient.functions().httpsCallable("sms_sender");

    const link = `${process.env.REACT_APP_SIGNUP_CONTINUE_LINK}/${this.uid}`;
    return sms_sender({
      phone: this.phone,
      msg_type: "sp_initial_signup",
      msg_data: { link: link }
    });
  }

  async saveSession() {
    try {
      const docRef = await this.fbClient
        .firestore()
        .collection("signupSession")
        .doc(this.uid)
        .set({
          id: this.uid,
          u: this.email,
          p: this.password,
          s: "i",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });

      let smsPro, emailPro;

      smsPro = this.sendSessionSmsLink();

      emailPro = await this.sendSessionEmailLink();

      try {
        await Promise.all([emailPro, smsPro]);
      } catch (err) {
        console.log("Email or/and SMS failed to send.");
        console.log(err);
      }

      return true;
    } catch (err) {
      console.log(err);
    }
  }

  markSessionComplete() {
    this.fbClient.auth().signOut();
    this.completeLater = true;
  }

  async restoreSession(ses) {
    try {
      //Search for session
      const docRef = await this.fbClient
        .firestore()
        .collection("signupSession")
        .doc(ses)
        .get();
      console.log(docRef);
      //Load session data
      const info = docRef.data();
      this.email = info.u;
      this.password = info.p;
      const user = await this.login();

      //If session incomplete find provider info
      if (info.s === "i") {
        const spRef = await this.fbClient
          .firestore()
          .collection("serviceProvider")
          .doc(ses)
          .get();

        //If no provider found throw error
        if (!spRef.exists) {
          this.fbClient.auth().signOut();
          throw new Error("Not Found");
        }

        //Found load data into data store
        const spData = { ...spRef.data(), id: spRef.id };
        this.signupStatus = this.SUCCESSFUL;
        this.email = spData.email;
        this.password = info.p;
        this.companyName = spData.companyName;
        this.uid = ses;
      } else {
        //If signup has been completed set flag
        this.signupStatus = this.COMPLETE;
      }

      this.fbClient.auth().signOut();
      return true;
    } catch (e) {
      console.log(e);
      this.signupStatus = this.ERRORS;
      return false;
    }
  }
}

decorate(SignUpStore, {
  termsConditions: observable,
  w9taxIdType: observable,
  w9companyType: observable,
  w9ssnEin: observable,
  licUpload: observable,
  insUpload: observable,
  checking: observable,
  completeLater: observable,
  fileUploading: observable,
  signupLoc: observable,
  emailAddressAvailable: observable,
  errModal: observable,
  email: observable,
  password: observable,
  phone: observable,
  firstName: observable,
  lastName: observable,
  companyName: observable,
  bankName: observable,
  accountNumber: observable,
  routingNumber: observable,
  address: observable,
  city: observable,
  state: observable,
  zip: observable,
  vehicleType: observable,
  working: observable,
  signupStatus: observable,
  maxServiceArea: observable,
  validSignupForm: computed,
  checkIfEmailExist: action
});

export default SignUpStore;
