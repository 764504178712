import React, { Component } from "react";
import { observer, inject } from "mobx-react";

class FinishLater extends Component {
  componentWillUnmount() {
    this.props.store.resetSignup();
  }

  render() {
    return (
      <div>
        Thanks! Remember to check your email and click the link provided to
        continue later.
      </div>
    );
  }
}
export default inject("store")(observer(FinishLater));
