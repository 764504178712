export { routes };

const routes = {
  start: { path: "/1", step: 1 },
  confirm: { path: "/1/confirm", step: 2 },
  complete: { path: "/1/complete", step: 3 },
  updl: { path: "/2", step: 4 },
  upins: { path: "/2/upins", step: 5 },
  taxinfo: { path: "/2/taxinfo", step: 6 },
  taxconfirm: { path: "/2/taxconfirm", step: 7 },
  finished: { path: "/2/finished", step: 8 },
  retses: { path: "/ret/ses/:id" }
};
