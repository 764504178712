import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Redirect } from "react-router-dom";
import { routes } from "../lib/routes";

class FinishedPage extends Component {
  componentWillUnmount() {
    this.props.store.resetSignup();
  }

  render() {
    return (
      <div>
        Registration Complete. Please allow 3 to 5 business days for us to
        review your information and get it processed.
      </div>
    );
  }
}

export default inject("store")(observer(FinishedPage));
