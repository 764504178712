import React from "react";
import { Divider, List, Header, Checkbox } from "semantic-ui-react";
import { VEHICLE_TYPE } from "eemerg-models/dist/app_constants";
import { observer } from "mobx-react";

const Confirmation = props => {
  const { store } = props;

  const handleTerms = (e, data) => {
    store.termsConditions = data.checked;
  };

  const showVehicleType = v => {
    const vt = VEHICLE_TYPE.find(o => {
      return o.value == v;
    });

    return vt.text;
  };

  return (
    <div>
      <Divider horizontal>Please Confirm Information</Divider>
      <Divider horizontal>
        <Header as={"h4"}>Account Holder Information</Header>
      </Divider>

      <List>
        <List.Item>
          <List.Content>
            <List.Header>Name</List.Header>
            <List.Description>
              {store.firstName} {store.lastName}
            </List.Description>
          </List.Content>
        </List.Item>

        <List.Item>
          <List.Content>
            <List.Header>Email</List.Header>
            <List.Description>{store.email}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>Phone</List.Header>
            <List.Description>{store.phone}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>Password</List.Header>
            <List.Description>********</List.Description>
          </List.Content>
        </List.Item>
      </List>

      <Divider horizontal>
        <Header as={"h4"}>Company Information</Header>
      </Divider>
      <List>
        <List.Item>
          <List.Content>
            <List.Header>Company Name</List.Header>
            <List.Description>{store.companyName}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>Address</List.Header>
            <List.Description>
              {store.address}
              <br />
              {store.city}, {store.state} {store.zip}
            </List.Description>
          </List.Content>
        </List.Item>

        <List.Item>
          <List.Content>
            <List.Header>Service Vehicle</List.Header>
            <List.Description>
              {showVehicleType(store.vehicleType)}
            </List.Description>
          </List.Content>
        </List.Item>

        <List.Item>
          <List.Content>
            <List.Header>Coverage Range</List.Header>
            <List.Description>{store.maxServiceArea}</List.Description>
          </List.Content>
        </List.Item>
      </List>

      <Divider horizontal>
        <Header as={"h4"}>Terms & Conditions</Header>
      </Divider>

      <div>
        <sub>
          In order to proceed you must agree to all terms and conditions. Click
          the check box
        </sub>
      </div>
      <br />

      <Checkbox
        label={"I agree to all terms and conditions."}
        checked={store.termsConditions}
        onChange={handleTerms}
      />
      <div>
        <a target={"blank"} href="https://eemerg.com/sp_terms.html">
          READ TERMS & CONDITIONS
        </a>
      </div>
    </div>
  );
};

export default observer(Confirmation);
