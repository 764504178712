import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

class Upload extends Component {
  state = {
    fileName: "NO FILE SELECTED",
    selected: false,
    uploadPerformed: false
  };
  handleInputAction = e => {
    if (e.target.files.length > 0) {
      //TODO check file size and only permit files 2mb or smaller.
      this.setState({ fileName: e.target.files[0].name, selected: true });
      this.props.inputAction(e);
    } else {
      this.setState({
        fileName: "NO FILE SELECTED",
        selected: false
      });
    }
  };

  handleUploadAction = e => {
    if (this.state.selected) {
      this.props.uploadAction(e);
    }
  };

  render() {
    const { uploadAction, inputAction } = this.props;

    const hiddenButton = {
      opacity: "0",
      position: "absolute",
      pointerEvents: "none",
      width: "1px",
      height: "1px"
    };

    return (
      <>
        {!this.props.uploadPerformed ? (
          <div>
            <label className={"ui button"} htmlFor={"dlf"}>
              Choose File
            </label>

            <input
              id={"dlf"}
              type={"file"}
              accept={"image/jpg"}
              style={hiddenButton}
              onChange={this.handleInputAction}
              data-testid="getFile"
            />
            <Button
              content={"Upload"}
              onClick={this.handleUploadAction}
              loading={this.props.working}
              disabled={!this.state.selected}
              data-testid={"uploadButton"}
            />
            <span>{this.state.fileName}</span>
          </div>
        ) : (
          <div>{this.props.completeMsg}</div>
        )}
      </>
    );
  }
}

Upload.propTypes = {
  inputAction: PropTypes.func.isRequired,
  uploadAction: PropTypes.func.isRequired,
  uploadPerformed: PropTypes.bool,
  completeMsg: PropTypes.string
};

export default Upload;
