import React, { Component } from "react";
import { Button, Divider, Segment } from "semantic-ui-react";
import { observer } from "mobx-react";
import { routes } from "../lib/routes";

class CompleteComponent extends Component {
  handleContinue = () => {
    const { store, history } = this.props;

    store.setQuickLogin();
    history.push(routes.updl.path);
  };

  handleFinishLater = () => {
    const { store } = this.props;

    store.markSessionComplete();
  };

  render() {
    const { store } = this.props;
    const style = { width: "100%" };
    return (
      <div>
        <Divider horizontal>
          <h1>Congrats</h1>
        </Divider>
        <p>
          You have completed the inital steps to become a service provider for
          Eemerg. We still need you to upload important documentation and submit
          your tax information. If you are ready to do that now please click the
          continue button to go the the next steps. If you would like, you can
          also finish these steps later when you have everything available.
        </p>

        <div>
          <Segment textAlign={"center"} basic>
            <Button
              content={"Continue"}
              color={"red"}
              onClick={this.handleContinue}
            />
            <span> </span>
            <Button
              onClick={this.handleFinishLater}
              content={"Finish Later"}
              color={"red"}
            />
          </Segment>
          <Segment basic textAlign={"center"}>
            <sub>(Please select an option to proceed)</sub>
          </Segment>
        </div>
      </div>
    );
  }
}

export default observer(CompleteComponent);
