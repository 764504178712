import React, { Component } from "react";
import { Step, Icon } from "semantic-ui-react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { routes } from "../lib/routes";

class StepProgress extends Component {
  state = {
    start: true,
    confirm: false,
    complete: false,
    startc: false,
    confirmc: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.history.listen((loc, action) => {
      console.log("History func invoked");
      switch (loc.pathname) {
        case routes.start.path:
          this.setState({
            start: true,
            confirm: false,
            complete: false
          });
          break;
        case routes.confirm.path:
          this.setState({
            start: false,
            confirm: true,
            complete: false
          });
          this.setState({ startc: true });
          break;
        case routes.complete.path:
          this.setState({
            start: false,
            confirm: false,
            complete: true
          });
          this.setState({ confirmc: true });
          break;
      }
    });
  }

  render() {
    return (
      <>
        <div className={"larger"}>
          <Step.Group fluid width={4} size={"mini"}>
            <Step active={this.state.start} completed={this.state.startc}>
              <Icon name={"signup"} size={"mini"} />
              <Step.Content>
                <Step.Title> Signup Information</Step.Title>
                <Step.Description />
              </Step.Content>
            </Step>
            <Step active={this.state.confirm} completed={this.state.confirmc}>
              <Icon name={"check circle outline"} />
              <Step.Content>
                <Step.Title>Confirm</Step.Title>
              </Step.Content>
            </Step>
            <Step active={this.state.complete}>
              <Icon name={"flag checkered"} />
              <Step.Content>
                <Step.Title>Complete</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>
      </>
    );
  }
}

export default withRouter(observer(StepProgress));
