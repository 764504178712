import React, { Component } from "react";
import { Button, Input, Label } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import Upload from "../components/UploadComponent";

class InsuranceUploadPage extends Component {
  state = {
    imgData: undefined,
    file: undefined
  };

  constructor(props) {
    super(props);
  }

  handleImageDl = (e, data) => {
    console.log(e.target.files);
    const { files } = e.target;

    if (files.length > 0) {
      const reader = new FileReader();

      reader.onload = tf => {
        console.log(tf);
        this.setState({ imgData: tf.target.result });
      };

      this.setState({ file: e.target.files[0] });
      const dataUrl = reader.readAsDataURL(e.target.files[0]);
    }
  };

  uploadIns = () => {
    this.props.store.uploadInsurance(this.state.file, "deefile");
  };

  render() {
    return (
      <>
        <div>
          <div>
            <h3>Insurance Declaration</h3>

            <Upload
              inputAction={this.handleImageDl}
              uploadAction={this.uploadIns}
              working={this.props.store.fileUploading}
              uploadPerformed={this.props.store.insUpload}
              completeMsg={"Insurance upload complete."}
            />
            <div>
              <sub>File type must be a jpeg</sub>
            </div>
          </div>

          <div id={"odl"}>
            <img style={{ width: "300px" }} src={this.state.imgData} />
          </div>
        </div>
      </>
    );
  }
}

export default inject("store")(observer(InsuranceUploadPage));
