import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { routes } from "../lib/routes";
import { observer } from "mobx-react";

class SessionProxy extends Component {
  constructor(props) {
    super(props);
    const { store, match } = props;
    console.log(match.params.id);
    store.restoreSession(match.params.id);
  }

  render() {
    const { store } = this.props;

    if (store.signupStatus === store.ERRORS) {
      return <div>NO SESSION FOUND!</div>;
    }
    if (store.signupStatus === store.COMPLETE) {
      return <Redirect to={routes.finished.path} />;
    }
    //TODO Add code here for sign up being complete.
    if (store.signupStatus === store.SUCCESSFUL) {
      return <Redirect to={routes.updl.path} />;
    }
    return <p className={"loading"}>Searching for saved session</p>;
  }
}

export default observer(SessionProxy);
