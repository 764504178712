import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import CompleteComponent from "../components/CompleteComponent";
import FinishLater from "../components/FinishLaterComponent";
import { Redirect } from "react-router-dom";
import { routes } from "../lib/routes";

class CompletePage extends Component {
  render() {
    const { store, history } = this.props;
    if (store.signupStatus === store.INITIAL) {
      return <Redirect to={routes.start.path} />;
    }
    return (
      <>
        {this.props.store.completeLater ? (
          <FinishLater />
        ) : (
          <CompleteComponent store={store} history={history} />
        )}
      </>
    );
  }
}

export default inject("store")(observer(CompletePage));
