import React, { Component } from "react";
import { Step, Icon } from "semantic-ui-react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { routes } from "../lib/routes";

class StageTwoStepProgress extends Component {
  state = {
    updl: false,
    upins: false,
    taxinfo: false,
    taxconfirm: false,
    finished: false,
    updlc: false,
    upinsc: false,
    taxinfoc: false,
    taxconfirmc: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      updl: true,
      upins: false,
      taxinfo: false,
      finished: false
    });
    this.props.history.listen((loc, action) => {
      switch (loc.pathname) {
        case routes.updl.path:
          this.setState({
            updl: true,
            upins: false,
            taxinfo: false,
            taxconfirm: false,
            finished: false
          });
          break;
        case routes.upins.path:
          this.setState({
            updl: false,
            upins: true,
            taxinfo: false,
            taxconfirm: false,
            finished: false
          });
          this.setState({ updlc: true });
          break;
        case routes.taxinfo.path:
          this.setState({
            updl: false,
            upins: false,
            taxinfo: true,
            taxconfirm: false,
            finished: false
          });
          this.setState({ upinsc: true });
          break;
        case routes.taxconfirm.path:
          this.setState({
            updl: false,
            upins: false,
            taxinfo: false,
            taxconfirm: true,
            finished: false
          });
          this.setState({ taxinfoc: true });
          break;
        case routes.finished.path:
          this.setState({
            updl: false,
            upins: false,
            taxinfo: false,
            taxconfirm: false,
            finished: true
          });
          this.setState({ taxconfirmc: true });
          break;
      }
    });
  }

  handleStep = () => {};

  render() {
    return (
      <>
        <div className={"larger"}>
          <Step.Group fluid unstackable width={5} size={"mini"}>
            <Step active={this.state.updl} completed={this.state.updlc}>
              <Icon />
              <Step.Content>
                <Step.Title>Driver's License</Step.Title>
                <Step.Description />
              </Step.Content>
            </Step>
            <Step
              completed={false}
              active={this.state.upins}
              completed={this.state.upinsc}
            >
              <Icon />
              <Step.Content>
                <Step.Title>Insurance</Step.Title>
                <Step.Description />
              </Step.Content>
            </Step>
            <Step active={this.state.taxinfo} completed={this.state.taxinfoc}>
              <Icon />
              <Step.Content>
                <Step.Title>Tax Info</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={this.state.taxconfirm}
              completed={this.state.taxconfirmc}
            >
              <Icon />
              <Step.Content>
                <Step.Title>Confirm</Step.Title>
              </Step.Content>
            </Step>
            <Step active={this.state.finished}>
              <Icon />
              <Step.Content>
                <Step.Title>Finished</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>
      </>
    );
  }
}

export default withRouter(observer(StageTwoStepProgress));
