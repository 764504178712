import { Validator, Rule } from "@cesium133/forgjs";
import { startCase } from "lodash";

const requireRuleSet = {
  type: "string",
  minLength: 1,
  notEmpty: true
};

const emailRule = new Rule(
  {
    type: "email",
    notEmpty: true
  },
  key => {
    const v = startCase(key);
    return `${v} does not contain a valid email`;
  }
);

const passwordRule = new Rule(
  {
    type: "password",
    minLength: 8
  },
  "Password must be at least 8 characters"
);

const emailExistRule = new Rule(
  {
    type: "boolean",
    toBe: true
  },
  "Email Address already in use"
);

const requireRule = new Rule(requireRuleSet, (key, value) => {
  let v = startCase(key);
  return `${v} can not be blank`;
});

const intRule = new Rule(
  {
    type: "int"
  },
  (key, value) => `${key} is not right`
);

const selectServiceAreaRule = new Rule(
  {
    type: "int"
  },
  (key, value) => {
    const v = startCase(key);
    return `${v} must have a range chosen`;
  }
);

const phoneRule = new Rule(
  {
    type: "string-int",
    minLength: 10,
    maxLength: 10
  },
  {
    type: key => {
      const v = startCase(key);
      return `${v} must be numeric and 10 digits`;
    },
    minLength: key => {
      const v = startCase(key);
      return `${v} must be 10 digits`;
    },
    maxLength: key => {
      const v = startCase(key);
      return `${v} can not be more than 10 digits`;
    }
  }
);

const zipRule = new Rule(
  {
    type: "string-int",
    minLength: 5,
    maxLength: 5
  },
  {
    type: "Zip must be numeric an 5 digits",
    maxLength: "Zip can not be more than 5 digits",
    minLength: "Zip must be 5 digits"
  }
);

const termsRule = new Rule(
  { type: "boolean", toBe: true },
  "Terms and Conditions must be accepted"
);

const routnumRule = new Rule(
  { type: "string-int", maxLength: 9, minLength: 9 },
  {
    type: key => {
      const v = startCase(key);
      return `${v} must be a number`;
    },
    maxLength: key => {
      const v = startCase(key);
      return `${v} can not be longer than 9 digits`;
    },
    minLength: key => {
      const v = startCase(key);
      return `${v} must be 9 characters long`;
    }
  }
);

const acctnumRule = new Rule(
  { type: "string-int" },
  {
    type: key => {
      const v = startCase(key);
      return `${v} must be a number`;
    }
  }
);

const einssnRule = new Rule(
  { type: "string-int", maxLength: 9, minLength: 9 },
  {
    type: key => {
      const v = startCase(key);
      return `EIN/SSN can only be 9 numbers.`;
    },
    maxLength: key => {
      const v = startCase(key);
      return `EIN/SSN can only be 9 numbers.`;
    },
    minLength: key => {
      const v = startCase(key);
      return `EIN/SSN can only be 9 numbers`;
    }
  }
);

const TermsValidator = new Validator({
  termsConditions: termsRule
});

const FinancialValidator = new Validator({
  routingNumber: routnumRule,
  accountNumber: acctnumRule,
  bankName: requireRule,
  w9ssnEin: einssnRule,
  w9taxIdType: requireRule,
  w9companyType: requireRule
});

const SignupValidator = new Validator({
  firstName: requireRule,
  lastName: requireRule,
  email: emailRule,
  emailAddressAvailable: emailExistRule,
  password: passwordRule,
  phone: phoneRule,
  companyName: requireRule,
  address: requireRule,
  city: requireRule,
  state: requireRule,
  zip: zipRule,
  vehicleType: requireRule,
  maxServiceArea: selectServiceAreaRule
});

export {
  emailRule as EmailRule,
  requireRule as RequireRule,
  phoneRule as PhoneRule,
  intRule as IntRule,
  SignupValidator,
  FinancialValidator,
  TermsValidator,
  Validator
};
