import React from "react";
import { Divider, List, Header } from "semantic-ui-react";
import { COMPANY_TYPES } from "eemerg-models/dist/app_constants";

const FinancialConfirmation = props => {
  const { store } = props;

  const showCompanyType = c => {
    const vt = COMPANY_TYPES.find(o => {
      return o.value == c;
    });

    return vt.text;
  };

  return (
    <div>
      <Divider horizontal>Please Confirm Information</Divider>
      <Divider horizontal>
        <Header as={"h4"}>Bank Account Information</Header>
      </Divider>

      <List>
        <List.Item>
          <List.Content>
            <List.Header>Bank Name</List.Header>
            <List.Description>{store.bankName}</List.Description>
          </List.Content>
        </List.Item>

        <List.Item>
          <List.Content>
            <List.Header>Bank Routing Number</List.Header>
            <List.Description>{store.routingNumber}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>Bank Account Number</List.Header>
            <List.Description>{store.accountNumber}</List.Description>
          </List.Content>
        </List.Item>
      </List>
      <Divider horizontal>
        <Header as={"h4"}>Tax Information</Header>
      </Divider>
      <List>
        <List.Item>
          <List.Content>
            <List.Header>Company Taxation Type</List.Header>
            <List.Description>
              {showCompanyType(store.w9companyType)}
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>Tax ID Type</List.Header>
            <List.Description>{store.w9taxIdType}</List.Description>
          </List.Content>
        </List.Item>

        <List.Item>
          <List.Content>
            <List.Header>EIN/SSN</List.Header>
            <List.Description>{store.w9ssnEin}</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </div>
  );
};

export default FinancialConfirmation;
